import React from "react"
import { blogs } from "../../content/Blogs"
import ContactSection from "../../components/common/ContactSection"
import InnerBlogPage from "../../components/molecules/InnerBlogPage"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const slug = `benefits-of-sda-housing-for-ndis-participants`;

const blogPost = blogs.find(post => post.slug === slug) // Find the blog post by slug

const WhatIsAnIndividualTaxReturnInAustralia = () => (
  <Layout>
    <Seo title="The Benefits of SDA Housing for NDIS Participants in Australia" />
    <Seo description="Discover how SDA housing in Australia enhances the quality of life for NDIS participants, providing specialised, accessible, and supportive living environments." />

    <InnerBlogPage post={blogPost} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default WhatIsAnIndividualTaxReturnInAustralia
